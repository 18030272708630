import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import NotFound from "../components/PageComponents/NotFound"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <NotFound />
  </Layout>
)

export default NotFoundPage
